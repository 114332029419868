.page-login-login,
.page-register-register,
.page-invitation-acceptinvitation,
.page-sent-passwordmailsent,
.page-password-forgotpassword,
.page-password-restorepassword,
.page-changed-passwordchanged {
    .main-wrapper {
        background-color: $grey--light;
    }

    .main-content {
        .collapsed:not(a) {
            display: none;
        }
    }

    .panel__header {
        font-weight: $font-weight--bold;
        padding: 35px 0 0;
    }

    .panel__body {
        padding-top: $space * 3;
        overflow: hidden;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    .panel__collapse-container {
        background-color: $blue;
        color: white;
        width: 48px;
        height: 48px;
    }

    .panel__collapse-button {
        font-size: $font-size--medium;
        font-weight: $font-weight--bold;
    }

    form label {
        font-weight: $font-weight--bold;
        margin-bottom: $space;

        &::after {
            display: none;
        }
    }

    .form__field--radio {
        margin-bottom: 0 !important;

        label {
            font-weight: $font-weight--base;
            margin-bottom: 0;
        }
    }

    @include media(lg) {
        .main-content .collapsed:not(a) {
            display: none;
        }

        .panel__header,
        .panel__body {
            padding: $space * 6;
        }

        .panel__header {
            border-bottom: 0;
            font-weight: $font-weight--bold;
        }

        .panel__body {
            padding-top: 0;
        }
        input {
            height: 40px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        input.touched {
            &.valid {
                background-position: calc(100% - 10px) -64px;
            }

            &.warning {
                background-position: calc(100% - 10px) -155px;
            }

            &.invalid {
                background-position: calc(100% - 10px) -93px;
            }
        }

        .login-wrapper {
            order: 1 !important;
        }

        .register-wrapper {
            order: 2 !important;
        }
    }
}

.page-login-login,
.page-password-forgotpassword {
    .register-link,
    .login-link {
        display: none;
    }

    .register-wrapper {
        .panel__body {
            display: none;
            animation-name: slideup;
        }
        .panel__collapse-button {
            transform: rotate(0deg);
        }
    }

    .login-wrapper {
        .panel__body {
            display: block;
            animation-name: slidedown;
        }
        .panel__collapse-button {
            transform: rotate(180deg);
        }
    }

    .register-wrapper {
        margin: ($space * 4) 0;
    }

    &.toggle-forms {
        .register-wrapper {
            .panel__body {
                display: block;
                animation-name: slidedown;
            }
            .panel__collapse-button {
                transform: rotate(180deg);
            }
        }

        .login-wrapper {
            .panel__body {
                display: none;
                animation-name: slideup;
            }
            .panel__collapse-button {
                transform: rotate(0deg);
            }
        }
    }

    @include media(lg) {
        .panel__body {
            display: block !important;
            animation: none;
        }

        .register-form,
        .login-link {
            display: none;
        }

        .login-form,
        .register-link {
            display: block;
        }

        &.toggle-forms {
            .register-wrapper {
                margin: 0;
                order: 1;
            }

            .login-wrapper {
                margin: ($space * 4) 0;
                order: 2;
            }

            .register-form,
            .login-link {
                display: block;
            }

            .login-form,
            .register-link {
                display: none;
            }
        }

        .register-wrapper {
            margin: 0;
            padding-left: $space;
        }

        .login-wrapper {
            margin: 0 !important;
            padding-right: $space;
        }
    }
}

.page-register-register {
    .login-link,
    .register-link {
        display: none;
    }

    .login-wrapper {
        .panel__body {
            display: none;
            animation-name: slideup;
        }
    }

    .register-wrapper {
        order: 1;
        .panel__body {
            display: block;
            animation-name: slidedown;
        }
        .panel__collapse-button {
            transform: rotate(180deg);
        }
    }

    .login-wrapper {
        margin: ($space * 4) 0;
        order: 2;
    }

    &.toggle-forms {
        .login-wrapper {
            .panel__body {
                display: block;
                animation-name: slidedown;
            }
            .panel__collapse-button {
                transform: rotate(180deg);
            }
        }

        .register-wrapper {
            .panel__body {
                display: none;
                animation-name: slideup;
            }
            .panel__collapse-button {
                transform: rotate(0deg);
            }
        }
    }
    @include media(lg) {
        .panel__body {
            display: block !important;
            animation: none;
        }

        .register-form,
        .login-link {
            display: block;
        }

        .login-form,
        .register-link {
            display: none;
        }

        &.toggle-forms {
            .login-wrapper {
                margin: 0;
                order: 1;
            }

            .register-wrapper {
                order: 2;
                margin: ($space * 4) 0;
            }

            .login-form,
            .register-link {
                display: block;
            }

            .register-form,
            .login-link {
                display: none;
            }
        }

        .login-wrapper {
            margin: 0;
            padding-right: $space;
        }

        .register-wrapper {
            margin: 0 !important;
            padding-left: $space;
        }
    }

    @keyframes slidedown {
        0% {
            display: block;
            max-height: 0;
            opacity: 0;
            padding: 0;
        }
        25% {
            opacity: 0;
            padding: $space * 3;
        }
        50% {
            opacity: 1;
            max-height: 600px;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes slideup {
        0% {
            opacity: 1;
            max-height: 600px;
            padding: $space * 3;
        }
        25% {
            padding: $space * 3;
            max-height: 0;
            opacity: 0;
        }
        30% {
            padding: 0;
            max-height: 0;
        }
        100% {
            opacity: 0;
            padding: 0;
            max-height: 0;
            display: none;
        }
    }
}

.page-sent-passwordmailsent,
.page-password-restorepassword,
.page-changed-passwordchanged {
    .panel__header {
        padding: 4 * $space;
        border-bottom: 0;
    }
    .main-content {
        margin-top: $space * 10;
    }
}

.page-login-login,
.page-register-register {
    .panel__header {
        padding: 0;
    }
}

;@import "sass-embedded-legacy-load-done:33";